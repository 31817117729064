import Header from "./sections/Header";
import About from "./sections/About";
import Projects from "./sections/Projects";
import Footer from "./sections/Footer";
import GameOfLife from "./sections/GameOfLife.jsx";
import { Helmet } from "react-helmet";
function App() {
  return (
    <div className="bg-background">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fredrik Etsare</title>
        <meta
          name="description"
          content="Fredrik Etsare Software Enginner Portfolio"
        />
        <meta
          name="keywords"
          content="Fredrik Etsare, Fredrik, Etsare, Portfolio, Projects, Software Engineer, Web Developer"
        ></meta>
      </Helmet>
      <div id="home">
        <Header />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="projects">
        <Projects />
      </div>
      <div>
        <GameOfLife />
      </div>
      <Footer />
    </div>
  );
}

export default App;
