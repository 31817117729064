import React from "react";
const logos = [
  { label: "Java", svg: "/svgs/java.svg", alt: "java logo" },
  { label: "React", svg: "/svgs/react.svg", alt: "react logo" },
  { label: "Javascript", svg: "/svgs/javascript.svg", alt: "javascript logo" },
  { label: "Nodejs", svg: "/svgs/nodejs-icon.svg", alt: "nodejs logo" },
  { label: "HTML", svg: "/svgs/html-5.svg", alt: "html logo" },
  { label: "CSS", svg: "/svgs/css-3.svg", alt: "css logo" },
  { label: "MySQL", svg: "/svgs/mysql-icon.svg", alt: "mysql logo" },
  { label: "C#", svg: "/svgs/Logo_Csharp_sharp.svg", alt: "c# logo" },
  { label: "Unity", svg: "/svgs/unity.svg", alt: "unity logo" },
  { label: "Figma", svg: "/svgs/Figma-logo.svg", alt: "figma logo" },
];

export default function About() {
  return (
    <div>
      <div className="max-h-[30vh] overflow-hidden flex flex-col-reverse spacer layer3"></div>

      <div className="bg-primary min-h-[50vh] flex flex-col md:flex-row gap-10 px-16">
        <div className="flex flex-col justify-center flex-1 gap-2">
          <h2 className="font-bold text-5xl text-background">Who am I?</h2>
          <p className="text-lg font-light text-secondary">
            I am a <strong className="font-bold">Software Engineer</strong>{" "}
            based in Sweden🇸🇪, passionate about crafting innovative{" "}
            <strong className="font-bold">solutions</strong> that bridge the
            realms of technology and user experience. With a profound love for
            both <strong className="font-bold">web </strong>
            and <strong className="font-bold">game development</strong>, I bring
            a diverse skill set and a keen eye for detail to every project.
          </p>
        </div>
        <div className="flex-1">
          <h1 className="text-3xl text-center mb-8 font-bold text-background">
            Skill Set
          </h1>
          <div className="grid grid-cols-5 gap-4">
            {logos.map((logo, index) => (
              <div className="flex flex-col text-background items-center gap-1 font-sans transition-transform duration-300 ease hover:scale-110">
                <img src={logo.svg} alt={logo.alt} className="w-16 h-16" />
                <p>{logo.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="h-[41vh] spacer layer5"></div>
    </div>
  );
}
