import React from 'react'

const GameOfLife = () => {
  return (
    <div className="w-full flex flex-col items-center gap-4">
      <a href="/life"><h1 className="text-[50px] font-bold">John Conway's Game of Life</h1></a>
      <video width="640" height="360" autoPlay loop muted>
        <source src="videos/gameoflife.mp4" type="video/mp4"/>
        Your browser does not support the video tag!
      </video>
      <a href="/life"><button className="p-4 bg-primary text-background rounded-md hover:scale-110 transition-transform duration-300 ease">Try it Now!</button></a>
    </div>
  )
}

export default GameOfLife
