import React, { useState, useEffect } from "react";

export default function ImageCycle({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div>
      <img
        src={images[currentIndex]}
        alt=""
        className="sm:w-full md:w-3/4 h-auto rounded-md object-cover"
      />
     
    </div>
  )
}
