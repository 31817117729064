import React from "react";
const links = [
  {
    href: "https://www.linkedin.com/in/fredrik-etsare-20a535255/",
    svg: "/svgs/linkedin-icon.svg",
  },
  { href: "https://github.com/Mammamu4", svg: "/svgs/github-icon.svg" },
];
export default function Footer() {
  return (
    <footer className="bg-primary min-h-[20vh] mt-10 flex flex-col gap-2 items-center justify-center">
      <p className="text-white">All Rights Reserved</p>
      <div className="flex gap-2">
        {links.map((link, index) => (
          <a href={link.href} key={index}>
            <img
              className="w-10 h-10"
              src={link.svg}
              alt={link.svg}
            />
          </a>
        ))}
      </div>
    </footer>
  );
}
