import React, { useState } from "react";
import ImageCycle from "../components/ImageCycle";
import {
  IconBrandReact,
  IconBrandAws,
  IconBrandNodejs,
  IconBrandMysql,
  IconBrandUnity,
  IconBrandCSharp,
  IconBrandMongodb,
  IconBrandJavascript,
  IconBrandCss3,
  IconBrandHtml5,
} from "@tabler/icons-react";
const projects = require("../assets/projects.json");

const techIcons = {
  React: <IconBrandReact />,
  AWS: <IconBrandAws />,
  "Node.js": <IconBrandNodejs />,
  MySQL: <IconBrandMysql />,
  Unity: <IconBrandUnity />,
  "C#": <IconBrandCSharp />,
  MongoDB: <IconBrandMongodb />,
  HTML: <IconBrandHtml5 />,
  CSS: <IconBrandCss3 />,
  Javascript: <IconBrandJavascript />,
};

export default function Projects() {
  const [showMore, setShowMore] = useState(false);
  const projectsToShow = showMore ? projects : projects.slice(0, 2);

  return (
    <div className="min-h-screen flex flex-col items-center">
      <h2 className="text-center text-4xl font-light">Projects</h2>
      <div className="w-full">
        {projectsToShow.map((project, index) => (
          <div
            className={`justify-center flex flex-col md:flex-row gap-8 p-10 my-8 shadow-lg transition-opacity duration-500 ease-in-out ${
              showMore || index < 3 ? "opacity-100" : "opacity-0"
            }`}
            key={index}
          >
            <div className=" flex flex-col gap-2 flex-1">
              <h3 className="text-4xl text-primary font-bold flex">
                <p>{project.name}</p>
                {project.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                 >
                    <img src="svgs/github-icon.svg" alt="github logo" />
                  </a>
                )}
              </h3>
              <p className="">{project.description}</p>
              <h4 className="text-lg font-bold">Features</h4>
              <ul className="ml-6">
                {project.features.map((feature, index) => (
                  <li className="list-disc" key={index}>
                    {feature}
                  </li>
                ))}
              </ul>
              <h4 className="text-lg font-bold">Key Takeaways</h4>
              <ul className="ml-6">
                {project.learnings.map((learning, index) => (
                  <li className="list-disc" key={index}>
                    {learning}
                  </li>
                ))}
              </ul>
              <div className="flex gap-4 mt-2">
                {project.technologies.map(
                  (tech, index) => techIcons[tech] || <span>{tech}</span>
                )}
              </div>
            </div>
            <div className="flex-1 w-full items-center">
              <ImageCycle images={project.images} />
            </div>
          </div>
        ))}
      </div>
      <button
        className="p-4 bg-primary text-background rounded-md hover:scale-110 transition-transform duration-300 ease"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show less" : "Show more"}
      </button>
    </div>
  );
}
